import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Preloader from "../../../../../components/Preloader"
import { gatewayURL } from "../../../../../store/api"
import axios from "axios"
import ButtonDelete from "../../../../../components/Buttons/button-delete"
import Pen from "../../../../../assets/images/pen.svg"
import Modal from "../../../../../components/Modal"
import Confirm from "../../../../../components/Confirm"
import { unlinkShopProduct } from "../../../../../store/SettingsStore/prod"

const LinkedBlock = ({product}) => {
    
    const companyShops = useSelector((state) => state.companyShops.list)

    const [list, setList] = useState([])

    useEffect(() => {
        let tmpList = []
        if (companyShops !== null && product.shopProductLinks) {

            companyShops.forEach(s => {
                let tmp = {
                    shop: s.shop
                }

                let findItems = product.shopProductLinks.find(shopLinks => shopLinks.shopCode === s.shop.code)

                if (findItems) {
                    tmp.items = findItems.shopProductID
                } else {
                    tmp.items = []
                }

                tmpList.push(tmp)
            })
        }
        setList(tmpList)
    }, [companyShops, product.shopProductLinks])

    if (companyShops === null) {
        return (
            <Preloader />
        )
    }

    return (
        <div className="simple-block">
            <h3>Связанные товары магазинов</h3>
            {list.map((s, i) => <LinkedShop shop={s} key={i} productID={product.ID} />)}
        </div>
    )
}

export default LinkedBlock

const LinkedShop = ({shop, productID}) => {

    const dispatch = useDispatch()

    const [m, setM] = useState(false)
    const [i, setI] = useState(null)

    const handlerUnlinkWork = (item, productID) => {
        const data = {
            productID : productID,
            shopProductID: [item.item],
            shopCode: item.code,
        }
        dispatch(unlinkShopProduct(data))
        setTimeout(() => {
            setM(false)
            setI(null)
        }, 500)
    }

    const handlerUnlink = (item) => {
        setI(item)
        setM(true)
    }

    const handlerClose = () => {
        setM(false)
        setI(null)
    }

    return (
        <div>
            <h4>{shop.shop.title}</h4>
            <div>
                {shop.items.length > 0 ? <LinkedRows shop={shop} 
                                                        handlerUnlink={handlerUnlink} /> : null}
            </div>

            {m && i ? <Modal close={handlerClose} body={<Confirm title="Отвязать?"
                        callbackYes={() => handlerUnlinkWork(i, productID)}
                        callbackNo={handlerClose} />} /> : null}
        </div>
    )
}

const LinkedRows = ({shop, handlerUnlink}) => {
    return (
        <>
            {shop.items.map((e, i) => <LinkedRow  handlerUnlink={handlerUnlink} 
                                                    key={i} 
                                                    item={e} 
                                                    code={shop.shop.code} /> )}
        </>    
    )
}

const LinkedRow = ({item, code, handlerUnlink}) => {
    const token = useSelector((state) => state.auth.token)

    const [prod, setProd] = useState(null)
    const [m, setM] = useState(false)
    const [u, setU] = useState(false)
    
    useEffect(() => {
        let config = {
            method: 'get',
            url: `${gatewayURL}/api/v1/shops/${code}/products/${item}`,
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
    
        axios.request(config)
            .then((response) => {
                setProd(response.data.result)
                setU(false)
            })
            .catch((error) => {
                console.log(error);
                setProd("error")
            });
    }, [item, token, code, u])

    const handlerUrl = (url) => {
        let config = {
            method: 'put',
            url: `${gatewayURL}/api/v1/shops/${code}/products/${item}`,
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: {
                url: url.trim(),
                active: prod.active
            }
        };
    
        axios.request(config)
            .then((response) => {
                setU(true)
                handlerClose()
            })
            .catch((error) => {
                console.log(error);
                setProd("error")
            });
    }

    const handlerClose = () => {
        setM(false)
    }

    if (prod === null) {
        return (
            <div>
                Загрузка...
            </div>
        )
    }

    if (prod === 'error') {
        return (
            <div>
                Ошибка загрузки
            </div>
        )
    }

    const FormUrl = () => {
        const [url1, setUrl1] = useState("")
        const [url2, setUrl2] = useState("")
        const [url3, setUrl3] = useState("")
        const [url4, setUrl4] = useState("")

        useEffect(() => {
            if (prod.url && prod.url !== "") {
                setUrls()
            }
        }, [])

        const getUrl = () => {
            let urlFin = ""
            
            if (url1 !== "") {
                urlFin = url1.trim() + "@"
            }
            if (url2 !== "") {
                urlFin = urlFin + url2.trim() + "@"
            }
            if (url3 !== "") {
                urlFin = urlFin + url3.trim() + "@"
            }
            if (url4 !== "") {
                urlFin = urlFin + url4.trim() + "@"
            }

            return urlFin
        }

        const setUrls = () => {
            let urlArr = prod.url.split('@')
            setUrl1(urlArr[0] ?? "")
            setUrl2(urlArr[1] ?? "")
            setUrl3(urlArr[2] ?? "")
            setUrl4(urlArr[3] ?? "")
        }

        return (
            <div>
                <h3 style={{marginTop: 0}}>Добавить ссылку</h3>
                <div className="form-row">
                    <label htmlFor="title">
                        <div className="form-label">Ссылка</div>
                        <input id="title"
                                style={{width: 600}}
                                name="title"
                                type="text"
                                className="text-field"
                                value={url1}
                                onChange={e => setUrl1(e.target.value)} />
                    </label>
                </div>
                <div className="form-row">
                    <label htmlFor="title2">
                        <div className="form-label">Ссылка</div>
                        <input id="title2"
                                style={{width: 600}}
                                name="title2"
                                type="text"
                                className="text-field"
                                value={url2}
                                onChange={e => setUrl2(e.target.value)} />
                    </label>
                </div>
                <div className="form-row">
                    <label htmlFor="title3">
                        <div className="form-label">Ссылка</div>
                        <input id="title3"
                                style={{width: 600}}
                                name="title3"
                                type="text"
                                className="text-field"
                                value={url3}
                                onChange={e => setUrl3(e.target.value)} />
                    </label>
                </div>
                <div className="form-row">
                    <label htmlFor="title4">
                        <div className="form-label">Ссылка</div>
                        <input id="title4"
                                style={{width: 600, marginBottom: 20}}
                                name="title4"
                                type="text"
                                className="text-field"
                                value={url4}
                                onChange={e => setUrl4(e.target.value)} />
                    </label>
                </div>
                <button className="button" onClick={() => handlerUrl(getUrl())}>Сохранить</button>
            </div>
        )
    }

    return (
        <>
        <div className="flex">
            <div style={{width: '40%'}} width="40%">{prod.title}</div>
            <div style={{width: '20%'}} width="20%">{prod.article}</div>
            <div style={{width: '30%'}} width="30%">
                <span style={{
                    backgroundImage: `url(${Pen})`,
                    backgroundRepeat: 'no-repeat',
                    padding: '3px 0 3px 28px',
                    cursor: 'pointer',
                    overflowWrap: 'anywhere'
                    }}
                    onClick={() => setM(true)}
                    >
                    {prod.url ? "Редактировать" : "Нет ссылки"}
                </span>
            </div>
            {/* <div style={{width: '5%'}} width="5%">{prod.active ? "on" : "off"}</div> */}
            <div style={{width: '5%'}} width="5%"><ButtonDelete handler={() => handlerUnlink({item, code})} /></div>
        </div>

        {m ? <Modal close={handlerClose} body={<FormUrl />} /> : null}
        </>
    )
}