import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../../store/NavStore/title"
import { useParams } from "react-router-dom"
import { getProds, setProdsList } from "../../../../../store/SettingsStore/prods"
import Preloader from "../../../../../components/Preloader"
import { getOzonUnsortedList } from "../../../../../store/ShopsStore/oz"
import { checkList, connect, setConn, setLinkerList, setLinkerProd } from "../../../../../store/SettingsStore/linker"
import Modal from "../../../../../components/Modal"
import { getWBUnsortedList } from "../../../../../store/ShopsStore/wb"
import { getYmUnsortedList } from "../../../../../store/ShopsStore/ym"

const Linker = () => {

    let {code} = useParams()

    const dispatch = useDispatch()

    const linkerProd = useSelector((state) => state.linker.prod)
    const linkerList = useSelector((state) => state.linker.list)
    const conn = useSelector((state) => state.linker.conn)
    const prods = useSelector((state) => state.prods.list)
    const prodsList = useSelector((state) => state[code].unsortedList)

    const [modal, setModal] = useState(false)
    const [titles, setTitles] = useState([])

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle('Связки товаров'))
        dispatch(setPageTitleBack({name: 'Назад', link: `/app/settings/products`}))
    }, [dispatch])

    useEffect(() => {
        return () => {
            dispatch(setProdsList([]))
            dispatch(setLinkerProd(null))
            dispatch(setLinkerList([]))
        }
    }, [dispatch])

    useEffect(() => {
        dispatch(getProds(
            {
                filters: [
                    {
                        key: "active", values: ["true"],
                    },
                    {
                        key: "limit", values: ["0"],
                    }
                ],
                page: 1,
                sort: [
                    {key: "name", order: "asc"}
                ]
            }
        ))
    }, [dispatch, conn])

    useEffect(() => {
        switch(code) {
            case "oz":
                dispatch(getOzonUnsortedList())
                break
            case "wb":
                dispatch(getWBUnsortedList())
                break
            case "ym":
                dispatch(getYmUnsortedList())
                break
            default:
                break
        }
    }, [code, dispatch, conn])

    const handlerConnect = () => {

        let titlesTmp = []
        let findProd = prods.find(e => e.ID === linkerProd)

        if (findProd) {
            titlesTmp.push(findProd.title)
        }

        linkerList.forEach(element => {
            let find = prodsList.find(e => e.ID === element)

            if (find) {
                titlesTmp.push(find.title)
            }
        });

        setTitles(titlesTmp)
        setModal(true)
    }

    const handlerClose = () => {
        setModal(false)
    }

    const handlerSave = (title) => {
        let data = {
            productID: linkerProd ?? 0,
            shopProductID: linkerList,
            name: title,
            shopCode: code
        }
        dispatch(connect(data))
    }

    useEffect(() => {
        if (conn) {
            dispatch(setConn(false))
            dispatch(setLinkerProd(null))
            dispatch(setLinkerList([]))
            handlerClose()
        }
    }, [conn, dispatch])

    return (
        <div style={{position: 'relative', maxHeight: '80vh', display: 'flex', paddingBottom: 60}}>
            <ProdsList />
            <ShopList code={code} />
            <Connector handlerConnect={handlerConnect} />
            {modal ? <Modal close={handlerClose} body={<ConnectorForm titles={titles} save={handlerSave} />} /> : null}
        </div>
    )
}

export default Linker

const ConnectorForm = ({titles, save}) => {

    const [title, setTitle] = useState(titles[0])

    return (
        <div>
            <h3>Выберите название</h3>
            {titles.map((e, i) => <div key={i}>
                            <input type="radio" checked={e === title} value={e} id={i} onChange={() => setTitle(e)} name="title" />
                            <label htmlFor={i}>{e}</label>
                        </div>)}
            <h3>или введите новое</h3>
            <div className="mb-12">
                <label htmlFor="other">
                    <div className="form-label">Название</div>
                    <input id="other"
                            name="other"
                            type="text"
                            className="text-field"
                            required
                            value={title}
                            onChange={e => setTitle(e.target.value)} />
                </label>
            </div>
            <button className="button" onClick={() => save(title)}>Сохранить</button>
        </div>
    )
}

const ProdsList = () => {
    const dispatch = useDispatch()

    const products = useSelector((state) => state.prods.list)
    const linkerProd = useSelector((state) => state.linker.prod)

    const handlerSelectProd = (id) => {
        if (id === linkerProd) {
            id = null
        }
        dispatch(setLinkerProd(id))
    }

    return (
        <div style={{overflow: 'auto', marginRight: 24}}>
            <table style={{width: '100%'}}>
                <tbody>
                    {products.map((e, i) => <ProductRow key={i} data={e} select={handlerSelectProd} />)}
                </tbody>
            </table>
        </div>
    )
}

const ProductRow = ({data, select}) => {

    const linkerProd = useSelector((state) => state.linker.prod)

    return (
        <tr>
            <td style={{padding: '6px 0px', borderBottom: '1px solid #ccc'}}>{data.title}</td>
            <td style={{padding: '6px 6px', borderBottom: '1px solid #ccc'}}>
                <ProductRowChips links={data.shopProductLinks} />
            </td>
            <td style={{width:50, padding: '6px 0px', borderBottom: '1px solid #ccc'}}>
                <input type="checkbox" checked={data.ID === linkerProd} onChange={() => select(data.ID)} />
            </td>
        </tr>
    )
}

const ProductRowChips = ({links}) => {
    const [c, setC] = useState([])

    useEffect(() => {
        let tmp = []
        if (links) {
            links.forEach(e => {
                tmp.push(
                    {
                        code: e.shopCode,
                        count: e.shopProductID.length
                    }
                )
            })
        }

        setC(tmp)
    }, [links])

    if (!links || links.length === 0) {
        return null
    }

    return (
        <div className="shop-count-chips">
            {c.map((e, i) => <div key={i}>{e.code}<span>{e.count}</span></div>)}
        </div>
    )
}

const ShopList = ({code}) => {

    const products = useSelector((state) => state[code].unsortedList)

    if (products === null) {
        return (
            <Preloader />
        )
    }

    return (
        <div style={{overflow: 'auto'}}>
            <table>
                <tbody>
                    {products.map((e, i) => <ShopRow key={i} data={e} />)}
                </tbody>
            </table>
        </div>
    )
}

const ShopRow = ({data}) => {

    const dispatch = useDispatch()

    const linkerList = useSelector((state) => state.linker.list)

    const [c, setC] = useState(false)

    useEffect(() => {
        let find = linkerList.find(e => e === data.ID)
        if (find) {
            setC(true)
        } else {
            setC(false)
        }
    }, [linkerList, data.ID])

    return (
        <tr>
            <td style={{width:50, padding: '6px 0px', borderBottom: '1px solid #ccc'}}>
                <input type="checkbox" checked={c} onChange={() => dispatch(checkList(data.ID))} />
            </td>
            <td style={{width: 150, padding: '6px 0px', borderBottom: '1px solid #ccc'}}>{data.article}</td>
            <td style={{padding: '6px 0px', borderBottom: '1px solid #ccc'}}>{data.title}</td>
        </tr>
    )
}

const Connector = ({handlerConnect}) => {

    const dispatch = useDispatch()

    const linkerProd = useSelector((state) => state.linker.prod)
    const linkerList = useSelector((state) => state.linker.list)

    const handlerReset = () => {
        dispatch(setLinkerProd(null))
        dispatch(setLinkerList([]))
    }

    if (linkerList.length === 0) {
        return null
    }

    return (
        <div className="connector">
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <button style={{marginRight: 12}} className="button cancel" onClick={handlerReset}>Сбросить выбранное</button>
                {linkerProd === null ? <button className="button" onClick={handlerConnect}>Создать новый</button> : <button className="button" onClick={handlerConnect}>Объединить</button>}
            </div>
        </div>
    )
}